import React, { useEffect } from "react";
import ActionCreator from "../ActionCreator";
import Selectors from "../Selectors";
import { connect } from "react-redux";
import { withPage } from "../PageContainer";
import TemplatesPage from "../Components/MyTemplates/TemplatesPage";
import { navigate } from "gatsby";

let windowGlobal = typeof window !== "undefined" && window;

/**
 * 我的範本頁面，主要透過 TemplatesPage 判斷
 * @return {MyTemplatesPage}
 */

function MyTemplatesPage(props) {
  const { profile, appActions } = props;
  useEffect(() => {
    //   // setLoading(true);
    windowGlobal = typeof window !== "undefined" && window;
    let token = windowGlobal.localStorage.getItem("token");
    let profile = windowGlobal.localStorage.getItem("profile");
    //   // async function fetchData() {
    //   //   try {
    //   //     let list = await Survey.Actions.fetchTemplateList();
    //   //     if (list.code && list.code == "406") {
    //   //       appActions.logout();
    //   //       navigate("/");
    //   //     } else {
    //   //       list.code && setData(list.data);
    //   //     }
    //   //   } catch (err) {
    //   //     setData(null);
    //   //   }
    //   // }
    //   // if (profile && token) {
    //   //   fetchData();
    //   // }

    if (!profile || !token) {
      appActions.logout();
      console.debug("navigate to root");
      navigate("/");
    }

    //   // setLoading(false);
  });

  // 是否隱藏 navbar 功能，最後放到 Layout.js 判斷
  // const mode = queryString.parse(props.location.search).mode === "popup";

  return <TemplatesPage popup={false} profile={profile} {...props} />;
}

export default withPage(
  connect(
    (state, ownProps) => ({
      profile: Selectors.getLoginUser(state),
    }),
    ActionCreator
  )(MyTemplatesPage)
);
